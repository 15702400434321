<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="用户id">
          <el-input
            style="width: 180px"
            v-model="uid"
            clearable
            placeholder="请输入用户id"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备租赁号">
          <el-input
            style="width: 180px"
            v-model="box_name"
            clearable
            placeholder="请输入设备租赁号"
          ></el-input>
        </el-form-item>
        <el-form-item label="续租状态">
          <el-select
            v-model="relet_sta"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option label="待续租" value="1"></el-option>
            <el-option label="已续租" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否逾期">
          <el-select
            v-model="is_pay"
            placeholder="选择逾期状态"
            style="width: 150px"
          >
            <el-option label="否" value="1"></el-option>
            <el-option label="是" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="searchinfo">搜索</el-button>
          <el-button type="primary" icon="el-icon-files" @click="dao">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="box_name" label="设备租赁号" align="center">
      </el-table-column>
      <el-table-column prop="uid" label="用户id" align="center">
      </el-table-column>
      <el-table-column prop="created_at" label="购买时间" align="center">
      </el-table-column>
      <el-table-column prop="last_buy_time" label="上次续租时间" align="center">
      </el-table-column>
      <el-table-column prop="price" label="续租金额" align="center">
      </el-table-column>
      <el-table-column prop="relet_sta" label="本月续租状态" align="center">
        <template slot-scope="scope">
          <el-link type="success" v-if="scope.row.relet_sta == 3"
            >已续租</el-link
          >
          <el-link type="danger" v-if="scope.row.relet_sta == 1"
            >待续租</el-link
          >
        </template>
      </el-table-column>

      <el-table-column prop="is_pay" label="是否逾期" align="center">
        <template slot-scope="scope">
          <div type="success" v-if="scope.row.is_pay === 2">是</div>
          <div type="danger" v-if="scope.row.is_pay === 1">否</div>
        </template>
      </el-table-column>
      <el-table-column prop="is_buy" label="已还周期" align="center">
      </el-table-column>

      <el-table-column prop="need_buy" label="应续周期" align="center">
      </el-table-column>
      <el-table-column prop="need_all_buy" label="待续周期" align="center">
      </el-table-column>
    </page-table>
  </div>
</template>

<script>
import { needPay } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import { areaListData } from "@/utils/area";

export default {
  name: "user",
  components: {
    pageTable,
  },
  data() {
    return {
      uid: "",
      relet_sta: "",
      box_name: "",
      is_pay: "",
      userList: [], // 列表
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {},
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    searchinfo() {
      let params = {
        page: 1,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        box_name: this.box_name,
        uid: this.uid,
        relet_sta: this.relet_sta,
        is_pay: this.is_pay,
      };
      needPay(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    getUserList() {
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        box_name: this.box_name,
        uid: this.uid,
        relet_sta: this.relet_sta,
        is_pay: this.is_pay,
      };
      needPay(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    dao() {
      console.log(this.$server.api,'1111111111')
      window.location.href =
        this.$server.api +"test/export"
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
